import { useConfig } from './useConfig'
import { GtmInjected } from '~/types/gtm'


export const useDatalayer = ($gtm: GtmInjected) => {
  const init = async () => {
    const { config, ensureConfig } = useConfig()
    await ensureConfig()

    const gtmId = config.value?.gtmSetting
    const enabled = config.value?.gtmEnabled

    if (enabled && gtmId) {
      $gtm.init(gtmId)
    }
  }

  return {
    init,
  }

}
