import { Dictionary } from 'ramda'
import {
  computed,
  ssrRef,
  useContext,
  useRoute
} from '@nuxtjs/composition-api'
import { Link } from '~/types/common'

const changedRoute = ssrRef<string | null>(null)
const changedLinks = ssrRef<Link[] | null>(null)

export const useLocale = () => {
  const { i18n, switchLocalePath } = useContext()
  const route = useRoute()

  const changeLocaleLinks = (links: Dictionary<string>) => {
    changedRoute.value = route.value.name as string
    changedLinks.value = i18n.locales.map(locale => ({
      key: locale.code,
      text: locale.code,
      url: links[locale.code]
    })).filter(link => link.url)
  }

  const clearLocaleLinks = () => {
    changedRoute.value = null
    changedLinks.value = null
  }

  const currentCode = computed(() => i18n.localeProperties.code)

  const localeLinks = computed(() => {
    if (changedRoute.value === route.value.name)
      return changedLinks.value

    return i18n.locales.map(locale => ({
      key: locale.code,
      text: locale.code,
      url: switchLocalePath(locale.code)
    } as Link))
  })

  return {
    currentCode,
    localeLinks,

    changeLocaleLinks,
    clearLocaleLinks
  }
}