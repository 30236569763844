import { ref } from '@nuxtjs/composition-api'

export enum BackdropOverlayZIndex {
  Z10 = 'z-10',
  Z20 = 'z-20',
  Z30 = 'z-30',
  Z40 = 'z-40',
  Z50 = 'z-50',
  Z60 = 'z-60',
  Z70 = 'z-70',
  Z80 = 'z-80',
  Z90 = 'z-90',
  Z100 = 'z-100',
  ZAuto = 'z-auto',
}

export interface BackdropOverlayOptions {
  zIndex?: BackdropOverlayZIndex
  lockScroll?: boolean
  fade?: boolean
  appendToBody?: boolean
}

const defaultOptions = {
  zIndex: BackdropOverlayZIndex.Z10,
  lockScroll: true,
  fade: true,
  appendToBody: true,
}

const visible = ref(false)
const options = ref<BackdropOverlayOptions>(defaultOptions)

export const useBackdropOverlay = () => {
  const open = (params?:BackdropOverlayOptions) => {
    visible.value = true
    options.value = Object.assign(defaultOptions, params ?? {})
  }

  const close = () => {
    visible.value = false
    options.value = defaultOptions
  }

  return {
    visible,
    options,

    open,
    close
  }
}
