import { useContext, useRoute } from '@nuxtjs/composition-api'

export const useRedirect = () => {
  const { localePath, redirect, from, i18n } = useContext()
  const route = useRoute()

  // Note: fromRouteName would be undefined when redirect from middleware, so need an extra query.redirect to redirect back
  const fromRouteName = from.value?.name?.split('___')[0]
  const queryRedirect = route.value?.query?.redirect
  const redirectObject = queryRedirect && JSON.parse(queryRedirect as string)

  const redirectBack = (locale: string = i18n.locale) => {
    if (redirectObject) {
      redirect(
        localePath(
          {
            name: redirectObject.name,
            params: redirectObject.params,
            query: redirectObject.query,
          },
          locale
        )
      )
    } else if (fromRouteName && fromRouteName !== 'login') {
      redirect(
        localePath(
          {
            name: fromRouteName,
            params: from.value?.params,
            query: from.value?.query,
          },
          locale
        )
      )
    } else {
      redirect(localePath({ name: 'index' }, locale))
    }
  }

  return {
    redirectBack,
  }
}
