import { computed } from '@nuxtjs/composition-api'
import { ContentPart } from '~/types/content'
import { useContent } from '~/composables/useContent'


export const useMenu = () => {

  const { menus } = useContent()

  const mainMenu = computed(() => menus.value?.[ContentPart.MainMenu] ?? [])

  const topMenu = computed(() => menus.value?.[ContentPart.TopMenu] ?? [])

  const footerMenu = computed(() => menus.value?.[ContentPart.FooterMenu] ?? [])

  const subFooterMenu = computed(() => menus.value?.[ContentPart.SubFooterMenu] ?? [])

  


  return {
  //  loading,
    mainMenu,
    topMenu,
    footerMenu,
    subFooterMenu
  }
}
